import React, { useState } from "react";
import { Credits } from "./Credits";
import { Launch } from "./Launch";
import { PizzaPeephole } from "./PizzaPeephole";

function App() {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  if (!active) {
    return <Launch onLaunchClick={() => setActive(true)} />;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <PizzaPeephole openedChanged={(isOpen) => setOpen(isOpen)} />
      <Credits visible={!open} />
    </div>
  );
}

export default App;
