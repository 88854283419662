import { clamp } from "lodash";
import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

const LIMIT = 150;
const OPEN_THRESHOLD = 100;
interface Props {
  onFullOpen: () => void;
  onClosing: () => void;
}

export const Pepperoni = ({ onFullOpen, onClosing }: Props) => {
  const [height, setHeight] = useState(84);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setHeight(document.getElementById("pepperoni")?.offsetWidth || 84);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
    var a = x1 - x2;
    var b = y1 - y2;

    if (y2 > y1) {
      return (x2 - x1) * 0.5;
    }

    if (x2 > x1) {
      return Math.sqrt(a * a + b * b);
    }

    return -Math.sqrt(a * a + b * b);
  };

  const [isPeepholeOpen, setPeepholeOpen] = useState(false);
  const [props, set] = useSpring(() => ({
    rotate: 0,
    x: "-12%",
    y: "-14%",
  }));

  const bind = useDrag(({ down, initial: [ix, iy], xy: [x, y] }) => {
    const rotate = down ? -clamp(getDistance(ix, iy, x, y), -LIMIT, LIMIT) : 0;
    set({
      rotate,
      immediate: down,
    });
    if (rotate > OPEN_THRESHOLD || rotate < -OPEN_THRESHOLD) {
      if (!isPeepholeOpen) {
        setPeepholeOpen(true);
        if (onFullOpen) {
          onFullOpen();
        }
      }
    } else {
      if (isPeepholeOpen) {
        setPeepholeOpen(false);
        if (onClosing) {
          onClosing();
        }
      }
    }
  });

  return (
    <animated.div
      className="pepperoni"
      id="pepperoni"
      {...bind()}
      style={{
        ...props,
        height,
        touchAction: "none",
      }}
    />
  );
};
