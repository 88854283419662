import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Loader } from "./Loader";
import { loadMedia } from "./loadMedia";
import { Pepperoni } from "./Pepperoni";
import { useTrackView } from "./useTrackView";
import ReactGA from "react-ga";

const ZOOM_SCALE = 4;

interface Props {
  openedChanged?: (open: boolean) => void;
}

export const PizzaPeephole = ({ openedChanged }: Props) => {
  useTrackView({
    category: "PizzaPeephole",
    action: "viewed",
  });

  const [imagesLoading, setImagesLoading] = useState(true);
  const [videoLoading, setVideoLoading] = useState(true);
  const [isPeepholeOpen, setPeepholeOpen] = useState(false);

  useEffect(() => {
    loadMedia(() => {
      setImagesLoading(false);
    });
  }, []);

  useEffect(() => {
    if (openedChanged) {
      openedChanged(isPeepholeOpen);
    }
  }, [isPeepholeOpen, openedChanged]);

  let peepVideo: HTMLVideoElement;

  const videoCanPlay = () => {
    peepVideo = document.getElementById("peepVideo") as HTMLVideoElement;

    peepVideo.onended = () => {
      setPeepholeOpen(false);
      set({ scale: 1 });
      peepVideo.currentTime = 0;
    };

    setVideoLoading(false);
  };

  peepVideo = document.getElementById("peepVideo") as HTMLVideoElement;

  const peepholeOpen = () => {
    setPeepholeOpen(true);
    set({ scale: ZOOM_SCALE });
    if (peepVideo) {
      ReactGA.event({
        category: "oh-my-god-vid",
        action: "viewed",
      });
      peepVideo.play();
    }
  };
  const peepholeClosing = () => {
    setPeepholeOpen(false);
    set({ scale: 1 });
    if (peepVideo) {
      peepVideo.pause();
      peepVideo.currentTime = 0;
    }
  };

  const [props, set] = useSpring(() => ({
    scale: 1,
    config: { mass: 5, tension: 350, friction: 120 },
  }));

  const mediaLoading = imagesLoading || videoLoading;

  return (
    <>
      {mediaLoading && <Loader />}
      <animated.div className="pizza" style={props}>
        <Pepperoni onFullOpen={peepholeOpen} onClosing={peepholeClosing} />
      </animated.div>
      <div
        className="peepVideoWrapper"
        style={{
          opacity: isPeepholeOpen ? 1 : 0,
        }}
      >
        <video
          id="peepVideo"
          onCanPlayThrough={videoCanPlay}
          onLoadedMetadata={videoCanPlay}
          playsInline
        >
          <source src="star_wars.mp4" type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
    </>
  );
};
