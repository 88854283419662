import React from "react";
import { useSpring, animated } from "react-spring";
import { useTrackView } from "./useTrackView";

export const Loader = () => {
  useTrackView({
    category: "Loader",
    action: "viewed",
  });

  const props = useSpring({
    loop: true,
    from: { rotateZ: 0 },
    to: { rotateZ: 180 },
  });

  return (
    <div className="loader-overlay">
      <animated.div className="loader" style={props} />
      <div className="loading-label">Loading</div>
    </div>
  );
};
