import za from './za.png'
import pepp from './pepp.png'

export const loadMedia = async (callback: () => void) => {
    await Promise.all([
        loadImage(za),
        loadImage(pepp),
        artificialDelay()
    ])

    callback()
}

const loadImage = (src: string) => new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src;
    img.onload = resolve;
    img.onerror = reject
})

const artificialDelay = () => new Promise((resolve) => setTimeout(resolve, 1300))
