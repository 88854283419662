import React from "react";
import { useTrackView } from "./useTrackView";

interface Props {
  onLaunchClick: () => void;
}

export const Launch = ({ onLaunchClick }: Props) => {
  useTrackView({
    category: "Launch Screen",
    action: "viewed",
  });

  return (
    <div className="start">
      <span className="za">🍕</span>
      <div>
        <a href="#" onClick={onLaunchClick}>
          Peer into the Pepperoni Pizza Pie Peephole
        </a>
      </div>
    </div>
  );
};
