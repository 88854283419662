import React from "react";

interface Props {
  visible?: boolean;
}

export const Credits = ({ visible }: Props) => {
  const getStyle = () => {
    if (visible) {
      return {};
    } else {
      return { height: 0, maxHeight: 0, paddingTop: 0, paddingBottom: 0 };
    }
  };
  return (
    <div className="credits" style={getStyle()}>
      <span className="za">🍕</span>
      {/* <span>
        <a href="https://www.youtube.com/user/RedLetterMedia/">
          www.youtube.com/user/RedLetterMedia/
        </a>
      </span>
      <div>
        <a href="https://www.redlettermedia.com/">www.redlettermedia.com/</a>
      </div> */}
    </div>
  );
};
